@import '../App.scss';

footer {
   margin: 100px 30px 0 30px;
   padding: 20px 1.5rem 0 1.5rem;
   color: white;
   font-size: .8rem;
   border-top: 2px solid white;
   width: 100%;
 }
 
 .socials {
   font-size: 2rem;
   width: 100%
 }
 
 .socials a {
   color: var(--neutral-gray);
 }
 
 .socials a:hover {
   color: var(--orange);
 }
 
 footer span {
   color: var(--orange);
   padding-right: 5px;
 }

 .partners--header {
  padding-bottom: 0px;
  margin-bottom: 0px;
 }

 .partner {
  height: 200px;
  border: 1px solid var(--bg-dropdown);
  border-radius: 10px;
  margin: 2px;
 }

 @include media-breakpoint-up(md) {
  .socials {
   width: 70%
   }
 }

 @include media-breakpoint-up(lg) {
  .socials {
   width: 60%;
   }  
 }