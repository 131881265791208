@import '../../../App.scss';

.disclaimer {
  background-color: var(--warning);
  color: black;
  border-radius: 10px;
  padding-top: 0px;
  font-size: 18px;
  padding-left: 0;
}

.class__heading span {
  text-transform: none;
  color: white;
  font-size: 16px;
  padding-right: 10px;
}

.grasslake__container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.class__heading {
    text-align: center;
    margin-bottom: 0px;
}

.btn-text {
  color: var(--dark-blue);
  margin-bottom: 0;
}

.purchase-list {
  padding-top: 20px;
}

.grasslake__container #bundle-option1, 
#installment-option1, 
#individual-option1 {
  color: var(--yellow);
  text-align: center;
}

.grasslake__container #bundle-option2, 
#installment-option2, 
#individual-option2 {
  color: var(--blue);
  text-align: center;
}

.grasslake__container .bundle-option1, 
.installment-option1, 
.individual-option1 {
  border: 0;
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin: .5rem 0;
  background-color: var(--yellow);
}

.grasslake__container .bundle-option1:hover, 
.installment-option1:hover, 
.individual-option1:hover {
  background-color: var(--light-yellow);
}
  
.grasslake__container .bundle-option2, 
.installment-option2, 
.individual-option2 {
  border: 0;
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin: .5rem 0;  
  background-color: var(--blue);
}

.grasslake__container .bundle-option2:hover, 
.installment-option2:hover, 
.individual-option2:hover {
  background-color: var(--light-blue);
}

@include media-breakpoint-up(md) {
  .grasslake__container {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    align-items: start;
    grid-gap: 3fr;
  }
}
