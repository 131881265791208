@import '../../App.scss';

.contact__container {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.am, .pm {
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
}

.contact-text-links {
   color: var(--orange);
   font-weight: 600;
 }
 
 .contact-text-links:hover {
   color: var(--neutral-gray);
 }
 
 .contact-btn {
   background-color: var(--orange);
   color: white;
   font-family: 'Montserrat', sans-serif;
   font-weight: bold;
   text-transform: uppercase;
   margin: 1.5rem .5rem;
 }
 
 .contact-btn:hover {
   background-color: var(--orange);
   color: var(--bg);
 }

 .contact__container p {
   text-align: center;
   margin: 0;
 }

@include media-breakpoint-up(md) {
  .contact__container {
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
   }
}