.player-wrapper {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  border: 1px solid var(--neutral-dark-gray);
}

.react-player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}