@import-normalize;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg: hsl(222, 42%, 11%);
  --bg-dropdown: hsl(223, 27%, 18%);
  --dark-orange: hsl(24, 96%, 32%);
  --orange: hsl(24, 98%, 43%);
  : hsl(24, 62%, 58%);
  --dark-blue: hsl(240, 100%, 13%);
  --neutral-gray: hsl(38, 8%, 73%);
  --neutral-dark-gray: rgb(128, 128, 128);
  --yellow: rgb(233, 191, 22);
  --light-yellow: rgb(231, 204, 98);
  --lotus-orange: hsl(38, 100%, 49%);
  --warning: white;
  --dark-blue: #0075a0;
  --blue: #00b0f0;
  --light-blue: #47c1ed;
  --ayha-red: #c4243d;
  --ayha-maroon: #8b0000;
  --lotus2: #B11B1C;
  --lotus1: #ffc20d;
  --lotus2-light: #af4141;
  --lotus1-light: #fad35f;
}