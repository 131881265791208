@import '../node_modules/bootstrap/scss/bootstrap.scss';

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--bg);
}

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  flex-basis: 100%;
}

h1, h2, h3, h4 {
  color: var(--neutral-gray);
}

h5 {
  color: var(--orange);
}

.arrow {
  width: 10%;
  height: 10%;
}

h6 {
  color: var(--neutral-gray);
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

h6 span {
  color: var(--dark-blue);
}

//sections
.section {
  padding: 5px 10px 0 10px;
}

.info {
  width: 100%;
  padding: 1.5rem 0;
  border-top: 2px solid white;
}

.info ul {
  padding-top: 0rem;
}

.info li {
  font-weight: 500;
  display: list-item;
  list-style-type: disc;
  padding-left: 0;
  padding-top: 0;
}

h4 {
  padding-top: 10px;
  flex-basis: 1
}

p {
  color: white;
  font-weight: 500;
  padding-top: .6rem;
}

.antioch {
  padding-top: 0;
  padding-bottom: 10px;
}

.teams-nav__container {
  width: 100%;
  border-top: 1px solid white;
}

.interpage-nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid white;
  background-color: var(--bg);
}

.interpage-nav-link {
  padding: .5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--neutral-gray);
}

.interpage-nav-link:hover {
  color: var(--neutral-dark-gray);
}

.camps__container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.leagues__subhead {
  text-align: center;
}


// Navbar styles
.navbar {
  display: flex;
  justify-content: center;
}

.navbar-toggler {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  background-color: none;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  border: none;
  border-radius: 2pt;
  box-shadow: 0 0 0 1pt grey;
  outline: none;
}

.navbar-nav {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  padding-top: 10px;
  flex-grow: 1;
}

.nav-links {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--neutral-gray);
  font-size: .9em;
  font-size: 1.2rem;
}

.nav-links:hover, .nav-links:active, .nav-links:visited {
  color: var(--orange);
  text-decoration: underline;
}

.navbar img {
  width: 200px;
  padding-bottom: 10px;
}

.navbar button {
  flex-basis: 100%;
  color: white;
}

.dropdown-menu {
  background-color: var(--bg-dropdown);
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background-color: var(--orange);
}

//camps
.camps-list {
    background-color: var(--neutral-gray);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
    color: black;
    text-transform: uppercase;
}

.current {
  background-color: var(--dark-blue);
}

.camps-display {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.camps-display p {
    padding-top: 0;
    padding-bottom: 1.5rem;
    margin: 0;
}  

.camps-contact {
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}

.links {
  color: var(--light-orange);
  font-weight: bold;
}

.links:hover {
    text-decoration: underline;
    color: var(--light-orange);
}

.camps__container h4 {
  text-align: center;
}

.camp__container,
.antioch-teams__container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.purchase {
  margin-top: 1.5rem;
  padding: 0;
}

.antioch-col {
  margin: 1.5rem;
  padding: 1rem;
  width: 100%;
}

.leagues__logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-image {
  max-width: 100%;
}

.purchase-subhead {
  text-align: center;
  margin-top: 1rem;
}

.purchase-list {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  margin-bottom: 30px;
}
 
.purchase-btn {
  border: 0;
  width: 100%;
  border-radius: 5px;
  padding: .5rem;
  margin: .5rem 0;
}

.bundle-text,
.ThreeDayBundle {
  color: var(--dark-blue);
  background-color: var(--orange);
  border-radius: 5px;
  // padding: 2px;
  // margin: 10px;
}

.purchase-btn-main-text {
  color: black;
  margin-bottom: 0;
}

.purchase-btn-main-text span {
  font-size: 1rem;
}

.line2 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.single-line, .line1 {
  padding-top: .5rem;
}

.purchase-btn--text {
  color: white;
  font-weight: bold;
}


.bundle,
.ThreeDayBundle {
  background-color: var(--dark-blue);
  font-size: 1.3rem;
}

.single,
.popin,
.Tuesday,
.Wednesday,
.Thursday {
  background-color: var(--neutral-dark-gray);
  font-size: 1rem;
}


.full-day,
.advanced,
.TwoDayBundle {
  background-color: var(--orange);
}

.team-btn {
  background-color: var(--orange);
}

.bundle:hover,
.bundle:active,
.ThreeDayBundle:hover,
.ThreeDayBundle:active,
.single:hover,
.single:active,
.popin:active,
.popin:hover,
.Tuesday:hover,
.Tuesday:active,
.Wednesday:hover,
.Wednesday:active,
.Thursday:hover,
.Thursday:active,
.full-day:hover,
.TwoDayBundle:hover,
.TwoDayBundle:active,
.advanced:hover,
.advanced:active {
  background-color: var(--light-orange);
  color: var(--bg);
}

.logistics {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  color: #fff;
  padding-left: 0;
  margin-top: 2.5rem;
}

.logistics--detail {
  padding-left: 1rem;
  margin-bottom: 1.5rem;
}

.logistics--detail ul li {
  color: white;
  padding-bottom: .4rem;
}

.logistics--detail ul {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  margin-left: 0;
  padding-left: 1rem;
}

.logistics--detail ul li {
  margin-left: 0;
  padding-left: .5rem; 
}

.st-listing {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.st-listing li {
  flex: 0 0 30%;
}

.st-logistics {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}


.st-logistics .dates {
  min-width: 225px;
  flex: 2 0 50%;
  justify-content: flex-start;
}

.camp__col {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    object-fit: contain;
}

.camp__col h4 {
  width: 100%;
  text-align: center;
}

.camp__img {
  width: 100%;
  height: auto;
  border: 1px solid var(--neutral-gray);
}

.modal-btn {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--dark-blue);
  font-family: 'Montserrat', sans-serif;
  border: none;
  padding: 1rem;
  text-align: center;
  background-color: var(--neutral-gray);
  margin: 0 1.5rem 0 1.5rem;
  border-radius: 5px;
  padding: .5rem;
  margin: .5rem 0;
}

.modal-btn:hover {
  background-color: var(--orange);
}

.modal-body, .modal-header, .modal-footer {
  background-color: var(--bg);
}

.table {
  color: #ffff;
}

.table thead {
  width: 100%;
}

.table__container {
  width: 100%;
}

.table__container h5,
.antioch-coaches h5 {
  text-align: center;
  color: var(--ayha-red);
  background-color: white;
  border-radius: 10px;
}

.antioch-coaches {
  display: flex;
  flex-flow: row wrap;
}

.coach__card {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.coach__card p {
  padding: 0;
  margin: 0;
}

.coach__card a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.coach__card a:hover {
  text-decoration: none;
}

.coach-links {
  color: var(--neutral-gray);
  font-weight: 600;
}

.coach-links:hover {
  color: var(--ayha-red);
}

.coach-img {
  border: 1px solid white;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: .9rem;
  }

  // .leagues__logo-container {
  //   width: 100%;
  // }
}

@include media-breakpoint-up(md) {
  html {
    font-size: .9rem;
  }

  p {
    text-align: left;
  }

  .logistics {
    margin-top: 1.5rem;
    padding-left: 2.2rem;
  }

  .logistics--detail {
    padding-left: 2.2rem;
  }

  .logistics--detail ul {
    padding-left: 0rem;
  }

  .coach__card img {
    max-width: 200px; 
  }

  //camps
  .st {
    text-align: left;
  } 

  .camp__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-gap: 1fr;
    width: 100%;
  }

  .camps__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }  

  .camp__col {
    width: 48%;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 852px;
  }

  .antioch-teams__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-gap: 1fr;
    width: 100%;
  }

  .antioch-col {
    margin: 0rem;
    margin-top: 1.5rem;
    padding: 0rem;
    width: 100%;
  }

  .table__container {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem
  }  
}

@include media-breakpoint-up(lg) {
  html {
    font-size: .9rem;
  }

  .section {
    padding: 5px 170px 0 170px;
  }

  p {
    text-align: left;
  }
  
  .navbar {
    justify-content: center;
    padding: 0 170px 0 170px;
  }

  .navbar img {
    width: 200px;
  }

  .nav-links {
    padding: 9.6px 0 9.6px 35px!important;
  } 
}