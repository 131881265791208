@import '../../App.scss';

.video__container {
  width: 100%;
  margin-top: 20px;
}

.home-btn-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--neutral-dark-gray);
  border-bottom: 1px solid var(--neutral-dark-gray);
  padding-top: 10px;
}

.home-btn-container h5 {
  text-align: center;
}

.home-btn-container h5 span {
  color: white;
}

.special-button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}


//current

.home-btn {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  font-family: 'Montserrat', sans-serif;
  border: none;
  padding: 1rem;
  text-align: center;
}

.special-btn {
  background-color: var(--neutral-gray);
  margin: 0rem .5rem 1rem .5rem;
  display: flex;
  flex-flow: column nowrap;
  min-width: 175px;
}

.special-btn span {
  font-size: .9rem;
  margin-top: -5px;
}

.special-btn:hover {
  background-color: var(--orange);
  color: black;
}

.special-img {
  width: 100%;
  height: auto;
  border: 1px solid var(--neutral-gray);
}

.current-comp-container {
  width: 100%;
}

.current-head {
  text-align: center;
  margin-bottom: 20px;
  color: white;
}

.current-vid__container {
  width: 100%;
}

.current-btn__container {
  margin-top: 20px;
  // margin-bottom: 20px;
  padding-bottom: 20px;
}

.current-btn {
  display: block;
  background-color: var(--dark-blue);
  margin: 0 1.5rem 0 1.5rem;
  text-decoration: none;
}

.current2-btn {
  background-color: var(--orange);
}

.current2-btn span {
  background-color: white;
  padding: 0 5px;
  border-radius: 5px;
}

.current-btn:hover {
  background-color: var(--light-orange);
  border: none;
  color: black;
}

.current3-btn {
  background-color: var(--neutral-dark-gray);
}

.current3-btn:hover {
  background-color: var(--orange);
}

.home__discount-msg {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid white;
}

.discount-special {
  color: var(--neutral-gray);
  font-size: 1rem;
  border-bottom: none;
  padding-bottom: 0;
}

.discount-special span {
  color: white;
}

.current__container {
  width: 100%;
  border-bottom: 2px solid var(--neutral-dark-gray);
}

//carousel
#carouselItems {
  border: 1px solid var(--neutral-dark-gray);
  width: 100%;
}

.w-100 {
  width: 100%;
  height: auto;
}

.carousel-item-next, 
.carousel-item-prev, 
.carousel-item.active {
  display: block;
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.45);
  padding: .5rem;
  border-radius: 10px;
}

.carousel-caption p {
  text-align: center;
}

.video-preview {
  color: var(--neutral-gray);
  padding-bottom: 10px;
}

@include media-breakpoint-up(md) {
  .current-btn {
   margin: 0 2rem 0 2rem;
 }

 .current__container {
   display: flex;
   flex-flow: row wrap;
 }

 .current-comp-container,
 .current-vid__container {
   width: 50%;
 }

 .current-comp-container {
   padding: 0 2rem;
 }

 .video__container {
   width: 70%;
 }
}

@media(min-width: 2000px) {
  .current-comp-container {
    padding: 0 8rem;
  } 
}