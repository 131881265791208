@import '../../App.scss';

.enrichment__container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.enrichment-gl-data__container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}  


h3 .subhead {
   color: var(--dark-blue);
 }
 
 .classes-col {
   padding-left: 2.5em;
   padding-bottom: 2.5em;
 }
 
 .classes {
   padding: 1.5rem;
  //  display: flex;
  //  flex-flow: row wrap;
  //  justify-content: center;
  //  align-items: center;
 }
 
 .classes li {
   display: flex;
   font-family: 'Montserrat', sans-serif;
   font-weight: 500;
   display: list-item;
   list-style-type: disc;
   padding-left: 0;
   padding-top: 0;
   color: yellowgreen;
 }

 .enrichment-video__container {
   width: 100%;
 }
 
 @include media-breakpoint-up(md) {
  .classes ul {
   margin-left: 2em!important;
   }

   .enrichment-gl-data__container {
    width: 59%;
  }  

   .enrichment-video__container {
     width: 39%;
   }
 }