@import '../../App.scss';

#about-story h4 {
  padding: 0;
}

#about-story h5 {
  margin-top: 20px;
}

#about-values,
#about-story {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}


.about-staff {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

#about-staff h5 {
  text-align: center;
}

#about-staff p {
  text-align: center;
}

#about-staff img {
   margin-top: .6rem;
   border: 1px solid var(--neutral-dark-gray);
   padding-bottom: 0;
 }

 .team-container {
   display: flex;
   flex-flow: column nowrap;
   align-items: center;
   border-top: 1px solid #fff;
   padding-top: 1.5rem;
 }

 .staff__card {
   display: flex;
   flex: 1 1 auto;
   flex-flow: column nowrap;
   align-items: center;
   justify-content: center;
   padding: 1rem;
 }

 .values-container ul {
  margin-bottom: 20px;
 }

 .values-container > ul li {
  margin-bottom: 1rem;
 }

 .values-container > ul li:nth-child(1) {
   margin-top: 1rem;
 }

 .values-list {
   color: #fff;
   padding-left: 1.5rem;
 }

 .values-list-item {
  padding-left: 1rem;
  color: #fff;
 }

 .values-container,
 .story-container {
   display: flex;
   flex-flow: column nowrap;
   align-items: center;
   justify-content: center;
   padding: 0;
   width: 100%;
 }

.staff__card a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.staff__card a:hover {
  text-decoration: none;
}


 .team-btn {
  text-transform: uppercase;
  color: var(--neutral-gray);
  font-weight: bold;
  border: 0;
  width: 100%;
  border-radius: 5px;
  padding: .5rem;
  margin: .5rem 0;
}

.team-btn:hover {
  background-color: var(--dark-blue);
  text-decoration: none;
}
 
 @include media-breakpoint-up(md) {

  #about-story h5 {
    margin-top: 0;
  }
  
  .info ul {
   margin-left: 2em!important;
   }

   .staff__card img {
    max-width: 200px; 
  }

 .about-staff {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: start;
  grid-gap: 1rem;
}

  .team-btn {
    width: 80%;
  }

  #about-values,
  #about-story {
    flex-flow: row wrap;
    width: 100%;
  }

  .values-container,
  .story-container {
    width: 50%;
    min-height: 385px;
    justify-content: flex-start;
  }

  .left-col {
    padding-right: 2rem;
  }

  .values-container ul {
    margin-bottom: 0;
   }  
 }

 @include media-breakpoint-up(lg) {
  .info ul {
    margin-left: 2em!important;
  }

  #about-story {
    padding-left: 0;
  }

  #about-values {
    padding-right: 0;
  }

  .values-list {
    width: 100%;
  }

  .values-container,
  .story-container {
    min-height: 821px;
  }

}